import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page gives a ‘top-down’ look at developing on KOPE.`}</p>
    </PageDescription>
    <h2>{`KOPE Development Workflow`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": ""
      }}>{`KOPE API`}</a>{` allows users to create their own Activities for users to run on the KOPE `}<a parentName="p" {...{
        "href": "https://kope.ai"
      }}>{`web platform`}</a>{`.
Once ready, users can deploy their code to KOPE.`}</p>
    <ul>
      <li parentName="ul">{`For guidance on developing a KOPE Activity, continue reading this page.`}</li>
      <li parentName="ul">{`For guidance on deploying an Activity to KOPE, see `}<a parentName="li" {...{
          "href": "../deployment"
        }}>{`Deployment`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      